import React from "react";
import { useState, useEffect } from "react";

import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import SignUp from "./components/Authentication/Signup/Signup";
import SignIn from "./components/Authentication/SignIn/SignIn"; 
import { useAuth, AuthProvider } from "./auth";
import Dashboard from "./components/Dashboard/Dashboard";
import Layout from "./components/Layout/Layout";
import RevenueInvoicesPage from "./pages/RevenueInvoicesPage/RevenueInvoicesPage";
import ExpenseInvoicesPage from "./pages/ExpenseInvoicesPage/ExpenseInvoicesPage";
import SuppliersPage from "./pages/SuppliersPage/SuppliersPage";
import CustomersPage from "./pages/CustomersPage/CustomersPage";
import SupplierCategoriesPage from "./pages/SupplierCategoriesPage/SupplierCategoriesPage";
import CustomerCategoriesPage from "./pages/CustomerCategoriesPage/CustomerCategoriesPage";
import Onboarding from "./components/Onboarding/Onboarding";
import Error404 from "./pages/Error404/Error404";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import AcceptInvitation from "./components/Authentication/AcceptInvitation/AcceptInvitation";
import AccountantDashboard from "./components/AccountantDashboard/AccountantDashboard";

import './styles/global.css';


const ProtectedRoute = ({ children }) => {
  const { user, organization, loading } = useAuth();
  const location = useLocation();
  const [isCheckingInvitation, setIsCheckingInvitation] = useState(true);

  useEffect(() => {
    const checkInvitation = async () => {
      const invitationAccepted = localStorage.getItem('invitationAccepted');
      if (invitationAccepted === 'true') {
        localStorage.removeItem('invitationAccepted');
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      setIsCheckingInvitation(false);
    };

    checkInvitation();
  }, []);

  if (loading || isCheckingInvitation) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (user.type === 'Accountant') {
    return <Layout>{children}</Layout>;
  }

  if (!organization && !isCheckingInvitation && user.type !== 'Accountant') {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  return <Layout>{children}</Layout>;
};



const AppRoutes = () => {
  console.log("AppRoutes");
  const { user, organization, loading, isImpersonating } = useAuth();
  
  console.log("user", user);
  console.log("organization", organization);
  console.log("loading", loading);
  console.log("isImpersonating", isImpersonating);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      <Route path="/login" element={
        user ? <Navigate to="/" replace /> : <SignIn />
      } />
      
      <Route path="/signup" element={
        user ? <Navigate to="/" replace /> : <SignUp />
      } />

    <Route path="/accept-invitation" element={<AcceptInvitation />} />

      <Route path="/onboarding" element={
        user 
          ? (!organization ? <Onboarding /> : <Navigate to="/dashboard" replace />)
          : <Navigate to="/login" replace />
      } />

      <Route path="/sign-out" element={
        <Navigate to="/login" replace />
      } />
      
      <Route path="/" element={
        !user 
          ? <Navigate to="/login" replace />
          : loading
            ? <LoadingSpinner />
            : (user.type === 'Accountant' && !isImpersonating
                ? <Navigate to="/accountant-dashboard" replace />
                : (!organization && !isImpersonating
                    ? <Navigate to="/onboarding" replace />
                    : <Navigate to="/dashboard" replace />
                  )
              )
      
      } />


      {user && (
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={
            user.type === 'Accountant' && !isImpersonating
              ? <Navigate to="/accountant-dashboard" replace /> 
              : <Dashboard />
          } />
          <Route 
            path="/accountant-dashboard" 
            element={
              user.type === 'Accountant' && !isImpersonating
                ? <AccountantDashboard />
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route path="/expense-invoices/*" element={<ExpenseInvoicesPage />} />
          <Route path="/expense-invoices/new" element={<ExpenseInvoicesPage isNewInvoice={true} />} />
          <Route path="/expense-invoices/:invoiceId" element={<ExpenseInvoicesPage />} />
          <Route path="/revenue-invoices/*" element={<RevenueInvoicesPage />} />
          <Route path="/revenue-invoices/new" element={<RevenueInvoicesPage isNewInvoice={true} />} />
          <Route path="/revenue-invoices/:invoiceId" element={<RevenueInvoicesPage />} />
          <Route path="/suppliers" element={<SuppliersPage />} />
          <Route path="/suppliers/new" element={<SuppliersPage isNewEntity={true} />} />
          <Route path="/customers" element={<CustomersPage />} />
          <Route path="/customers/new" element={<CustomersPage isNewEntity={true} />} />
          <Route path="/supplier-categories" element={<SupplierCategoriesPage />} />
          <Route path="/supplier-categories/new" element={<SupplierCategoriesPage isNewCategory={true} />} />
          <Route path="/customer-categories" element={<CustomerCategoriesPage />} />
          <Route path="/customer-categories/new" element={<CustomerCategoriesPage isNewCategory={true} />} />
          <Route path="/settings" element={<SettingsPage />} />
          
        </Route>
      )}
      {/* // ) : (
      //   <Route path="*" element={<Navigate to="/login" />} />
      // )} */}

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Router>
  );
}

export default App;
