import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/joy';
import { useAuth } from '../../auth';

import addIcon from '../../assets/icons/Add.svg';
import arrowIconLeftSidebar from '../../assets/icons/ArrowLineLeftSidebar.svg';

import dotsIcon from '../../assets/icons/ui-icon-dotsthreeoutlinevertical.svg';
import avatarImage from '../../assets/icons/avatar@2x.png';
import closeIcon from '../../assets/icons/Close.svg'; // Add this import
import clientsIcon from '../../assets/icons/UsersFour.svg';
import notificationsIcon from '../../assets/icons/Bell.svg';

import dashboardIcon from '../../assets/icons/House.svg';
import invoicesIconExpenses from '../../assets/icons/FolderNotchExpenses.svg';
import suppliersIconExpenses from '../../assets/icons/IdentificationCardExpenses.svg';
import categoriesIconExpenses from '../../assets/icons/ListBulletsExpenses.svg';
import suppliersIconRevenue from '../../assets/icons/IdentificationCardRevenue.svg';
import signOutIcon from '../../assets/icons/SignOut.svg';

import { ListBullets } from "@phosphor-icons/react";

import settingsIcon from '../../assets/icons/GearSix.svg';

const Sidebar = ({ className = "", onOpenUploader, onClose, isMobile, userType, isImpersonating }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { user, logout } = useAuth();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleItemClick = () => {
    if (isMobile) {
      onClose();
    }
  };

  const handleLogout = async () => {
    await logout();
    // Handle post-logout actions, like redirecting to the login page    
  };

  const isAccountant = userType === 'Accountant' && !isImpersonating;
  console.log("User Type:", userType);
  console.log("Is Impersonating:", isImpersonating);
  console.log("isAccountant:", isAccountant);



  return (
    <div className={`sidebar ${className} ${isCollapsed ? 'collapsed' : ''}`}>

      <div className="sidebar__toggle" onClick={toggleSidebar}>
        <img 
          src={arrowIconLeftSidebar}  
          alt="Collapse sidebar"
          className="double-arrow-icon"
        />
        <div className="sidebar__close" onClick={onClose}>
          <img src={closeIcon} alt="Close sidebar" />
        </div>
      </div>
      <div className="sidebar__content">
        <div className="sidebar__section">
          <NavLink 
            to="/dashboard" 
            className={({ isActive }) => 
              `sidebar__item ${isActive ? 'sidebar__item--active' : ''}`
            }
            onClick={handleItemClick}
          >
            <img src={dashboardIcon} alt="Dashboard" className="sidebar__icon sidebar__icon--black" />
            <span className="sidebar__label">Dashboard</span>
          </NavLink>
        
        </div>

        {isAccountant && (
          <div className="sidebar__section">
            <NavLink 
              to="/accountant/clients" 
              className={({ isActive }) => 
                `sidebar__item ${isActive ? 'sidebar__item--active' : ''}`
              }
              onClick={handleItemClick}
            >
              <img src={clientsIcon} alt="My Clients" className="sidebar__icon sidebar__icon--black" />
              <span className="sidebar__label">My Clients</span>
            </NavLink>
            <NavLink 
              to="/accountant/notifications" 
              className={({ isActive }) => 
                `sidebar__item ${isActive ? 'sidebar__item--active' : ''}`
              }
              onClick={handleItemClick}
            >
              <img src={notificationsIcon} alt="Notifications" className="sidebar__icon sidebar__icon--black" />
              <span className="sidebar__label">Notifications</span>
            </NavLink>
          </div>
        )}

        {!isAccountant && (
        <>
          <div className="sidebar__section">
            <div className="sidebar__header">
              <span className="sidebar__label">EXPENSES</span>
            </div>
            <div className="sidebar__item-wrapper">
              <NavLink 
                to="/expense-invoices" 
                className={({ isActive }) => 
                  `sidebar__item ${isActive ? 'sidebar__item--active sidebar__item--active-expense' : ''}`
                }
                onClick={handleItemClick}
              >
                <img src={invoicesIconExpenses} alt="Invoices" className="sidebar__icon sidebar__icon--expenses" />
                <span className="sidebar__label">Invoices</span>
              </NavLink>
              <button 
                className="sidebar__add-icon" 
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenUploader('expense');
                }}
              >
                <img src={addIcon} alt="Add" className="sidebar__custom-add-icon" />
              </button>
            </div>
            <NavLink 
              to="/suppliers" 
              className={({ isActive }) => 
                `sidebar__item ${isActive ? 'sidebar__item--active sidebar__item--active-expense' : ''}`
              }
              onClick={handleItemClick}
            >
              <img src={suppliersIconExpenses} alt="Suppliers" className="sidebar__icon sidebar__icon--expenses" />
              <span className="sidebar__label">Suppliers</span>
            </NavLink>
            <NavLink 
            to="/supplier-categories" 
            className={({ isActive }) => 
              `sidebar__item ${isActive ? 'sidebar__item--active sidebar__item--active-expense' : ''}`
            }
            onClick={handleItemClick}
          >
            <img src={categoriesIconExpenses} alt="Categories" className="sidebar__icon sidebar__icon--expenses" />
            <span className="sidebar__label">Categories</span>
          </NavLink>
          </div>
          <div className="sidebar__section">
            <div className="sidebar__header">
              <span className="sidebar__label">REVENUE</span>
            </div>        
            <div className="sidebar__item-wrapper">
              <NavLink 
                to="/revenue-invoices" 
                className={({ isActive }) => 
                  `sidebar__item ${isActive ? 'sidebar__item--active sidebar__item--active-revenue' : ''}`
                }
                onClick={handleItemClick}
              >
                <svg className="sidebar__icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                <g id="FolderNotch">
                  <path id="Vector" d="M12 7.5L9.19969 9.6C9.06987 9.69737 8.91197 9.75 8.74969 9.75H3V6C3 5.80109 3.07902 5.61032 3.21967 5.46967C3.36032 5.32902 3.55109 5.25 3.75 5.25H8.74969C8.91197 5.25 9.06987 5.30263 9.19969 5.4L12 7.5Z" fill="rgba(22, 97, 56, 1)"/>
                  <path id="Vector_2" d="M20.25 6.75H12.2503L9.64969 4.8C9.38967 4.60599 9.07411 4.5008 8.74969 4.5H3.75C3.35218 4.5 2.97064 4.65804 2.68934 4.93934C2.40804 5.22064 2.25 5.60218 2.25 6V18.75C2.25 19.1478 2.40804 19.5294 2.68934 19.8107C2.97064 20.092 3.35218 20.25 3.75 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V8.25C21.75 7.85218 21.592 7.47064 21.3107 7.18934C21.0294 6.90804 20.6478 6.75 20.25 6.75ZM3.75 6H8.74969L10.7503 7.5L8.74969 9H3.75V6ZM20.25 18.75H3.75V10.5H8.74969C9.07411 10.4992 9.38967 10.394 9.64969 10.2L12.2503 8.25H20.25V18.75Z" fill="rgba(22, 97, 56, 1)"/>
                </g>
                </svg>
                <span className="sidebar__label">Invoices</span>
              </NavLink>
              <button
                className="sidebar__add-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenUploader('revenue');
                }}  
              >
                <img src={addIcon} alt="Add" className="sidebar__custom-add-icon" />
              </button>
            </div>
            <NavLink 
              to="/customers" 
              className={({ isActive }) => 
                `sidebar__item ${isActive ? 'sidebar__item--active sidebar__item--active-revenue' : ''}`
              }
              onClick={handleItemClick}
            >
              <img src={suppliersIconRevenue} alt="Customers" className="sidebar__icon sidebar__icon--revenue" />
              <span className="sidebar__label">Customers</span>
            </NavLink>

            <NavLink 
            to="/customer-categories" 
            className={({ isActive }) => 
              `sidebar__item ${isActive ? 'sidebar__item--active sidebar__item--active-revenue' : ''}`
            }
            onClick={handleItemClick}
          >
            <ListBullets color="#166138" weight="regular" size={24} alt="Categories" className="sidebar__icon sidebar__icon--revenue" />
            <span className="sidebar__label">Categories</span>
          </NavLink>
          </div>
        </>
        )}


        <div className="sidebar__bottom">
          <NavLink 
            to="/settings" 
            className={({ isActive }) => 
              `sidebar__item ${isActive ? 'sidebar__item--active' : ''}`
            }
            onClick={handleItemClick}
          >
            <img src={settingsIcon} alt="Settings" className="sidebar__icon sidebar__icon--black" />
            <span className="sidebar__label">Settings</span>
          </NavLink>
          <NavLink 
            to="/sign-out" 
            className="sidebar__item"
            onClick={handleLogout}
          >
            <img src={signOutIcon} alt="Sign out" className="sidebar__icon sidebar__icon--black" />
            <span className="sidebar__label">Sign out</span>
          </NavLink>
          <div className="sidebar__profile">
            <div className="sidebar__profile-avatar">
              <Avatar 
                src={user?.photoURL || avatarImage} 
                alt={user?.displayName || 'User'} 
                className={isCollapsed ? 'avatar-small' : ''}
              />
            </div>
            <span className="sidebar__label">{user?.displayName || 'User'}</span>
            {/* <div className="sidebar__profile-dots">
              <img src={dotsIcon} alt="More options" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onOpenUploader: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,

};

export default Sidebar;