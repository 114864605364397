import { getIdToken } from '../authUtils';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const sendWelcomeEmail = async (userData) => {
  try {
    const token = await getIdToken();
    const response = await fetch(`${BACKEND_API_URL}/api/send-welcome-email`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: userData.email,
        name: userData.name,
        userType: userData.type,
        dashboardLink: `https://trysuma.com/dashboard/${userData.uid}`
      })
    });

    if (!response.ok) {
      throw new Error('Failed to send welcome email');
    }

    console.log('Welcome email sent successfully');
  } catch (error) {
    console.error('Error sending welcome email:', error);
  }
};

