import { useState, useEffect, createContext, useContext } from 'react';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getIdToken as getIdTokenUtil } from './authUtils';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {

  const [authState, setAuthState] = useState({
    user: null,
    organization: null,
    impersonatedOrg: null,
    loading: true
  });

  const fetchOrganizationsDetails = async (orgIds) => {
    if (!authState.user || authState.user.type !== 'Accountant') {
      throw new Error('Unauthorized access');
    }

    try {
      const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
      const token = await getIdToken();
      const queryParams = new URLSearchParams();
      orgIds.forEach(id => queryParams.append('orgIds', id));

      const response = await fetch(`${BACKEND_API_URL}/api/organizations?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch company details');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching company details:', error);
      throw error;
    }
  };



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            let organizationData = null;
            if (userData.organizationId) {
              try {
                const orgDoc = await getDoc(doc(db, 'organizations', userData.organizationId));
                if (orgDoc.exists()) {
                  const orgData = orgDoc.data();
                  organizationData = { id: userData.organizationId, ...orgData };
                }
              } catch (orgError) {
                console.error("Error fetching organization data:", orgError);
              }
            }
            setAuthState({
              user: { ...firebaseUser, ...userData },
              organization: organizationData,
              impersonatedOrg: null,
              loading: false
            });
          } else {
            setAuthState({
              user: firebaseUser,
              organization: null,
              impersonatedOrg: null,
              loading: false
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          setAuthState({
            user: firebaseUser,
            organization: null,
            impersonatedOrg: null,
            loading: false
          });
        }
      } else {
        setAuthState({
          user: null,
          organization: null,
          impersonatedOrg: null,
          loading: false,
          companyDetails: null
        });
      }
    });

    return () => unsubscribe();
  }, []);


  const refreshAuthState = async (firebaseUser) => {
    if (firebaseUser) {
        try {
            const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                let organizationData = null;
                if (userData.organizationId) {
                    const orgDoc = await getDoc(doc(db, 'organizations', userData.organizationId));
                    if (orgDoc.exists()) {
                        organizationData = { id: userData.organizationId, ...orgDoc.data() };
                    }
                }
                setAuthState({
                    user: { ...firebaseUser, ...userData },
                    organization: organizationData,
                    loading: false
                });
            } else {
                setAuthState({
                    user: firebaseUser,
                    organization: null,
                    loading: false
                });
            }
        } catch (error) {
            console.error("Error fetching user document:", error);
            setAuthState({
                user: firebaseUser,
                organization: null,
                loading: false
            });
        }
    } else {
        setAuthState({
            user: null,
            organization: null,
            loading: false
        });
    }
};


const logout = async () => {
  try {
    await signOut(auth);
    setAuthState(prev => ({ ...prev, impersonatedOrg: null }));
    // navigate to login page
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};

const getIdToken = async () => {
  if (authState.user) {
    return await getIdTokenUtil();
  } else {
    throw new Error('No authenticated user found');
  }
};

const setAccountantOrg = async (org) => {
  if (authState.user && authState.user.type === 'Accountant') {
    try {
      const orgDetails = await fetchOrganizationsDetails([org.id]);
      setAuthState(prev => ({ 
        ...prev, 
        impersonatedOrg: { ...org, ...orgDetails[org.id] }
      }));
    } catch (error) {
      console.error("Error fetching impersonated org details:", error);
      setAuthState(prev => ({ ...prev, impersonatedOrg: org }));
    }
  }
};

const exitAccountantOrg = () => {
  setAuthState(prev => ({ ...prev, impersonatedOrg: null }));
};


const value = {
  user: authState.user,
  organization: authState.impersonatedOrg || authState.organization,
  loading: authState.loading,
  isImpersonating: !!authState.impersonatedOrg,
  setAuthState,
  logout,
  getIdToken,
  refreshAuthState,
  setAccountantOrg,
  exitAccountantOrg,
  fetchOrganizationsDetails, // Add this line to include fetchCompanyDetails in the context value
};



return (
  <AuthContext.Provider value={value}>
    {!authState.loading && children}
  </AuthContext.Provider>
);
};