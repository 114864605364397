import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../../../auth";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../../firebase";
import { handleGoogleSignIn as authHandleGoogleSignIn } from '../../../authUtils';
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import './AcceptInvitation.css';

import sumaLogo from '../../../assets/logo.svg';
import googleIcon from '../../../assets/icons/google-icon.svg';
import appPreviewImage from '../../../assets/images/mockup.png';

const AcceptInvitation = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [isNewUser, setIsNewUser] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loading, getIdToken: getUserToken, refreshAuthState } = useAuth();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [invitationData, setInvitationData] = useState(null);
    const [token, setToken] = useState(null);
    const [invitationDetails, setInvitationDetails] = useState(null);


    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

    useEffect(() => {
        const fetchInvitation = async () => {
            const invitationId = new URLSearchParams(location.search).get('id');
            if (!invitationId) {
                setError('Invalid invitation link');
                return;
            }

            try {
                const invitationRef = doc(db, 'invitations', invitationId);
                const invitationSnap = await getDoc(invitationRef);

                if (invitationSnap.exists()) {
                    const data = invitationSnap.data();
                    setInvitationData(data);
                    setEmail(data.invitee.email);
                    setInvitationDetails(data);
                } else {
                    setError('Invitation not found');
                }
            } catch (error) {
                console.error("Error fetching invitation:", error);
                setError('Error fetching invitation data');
            }
        };

        fetchInvitation();
    }, [location]);

    const renderInvitationDetails = () => {
        console.log("Invitation Details:", invitationDetails);
        if (!invitationDetails) return null;

        const { inviter, invitee, invitationType } = invitationDetails;
        let message = '';

        switch (invitationType) {
            case 'user_to_org':
                message = `${inviter.name} has invited you to join their organization as a ${invitee.role}.`;
                break;
            case 'accountant_to_org':
                message = `${inviter.name} has invited you to be an accountant for their organization with the role of ${invitee.role}.`;
                break;
            case 'user_to_accountant':
                message = `${inviter.name} has invited you to join their accountant organization as a ${invitee.role}.`;
                break;
            case 'client_to_accountant':
                message = `${inviter.name} has invited you to be a client in their accountant organization.`;
                break;
            default:
                message = 'You have been invited to join an organization.';
        }

        return (
            <div className="invitation-details">
                <h2>You've been invited to join Suma!</h2>
                <p>{message}</p>
                <p>Invited by: {inviter.name} ({inviter.email})</p>
                <p>Your role will be: {invitee.role}</p>
                {/* <p>Login or sign up to accept the invitation.</p> */}

            </div>
        );
    };



    useEffect(() => {
        if (user) {
            getUserToken().then(setToken);
        }
    }, [user, getUserToken]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);

        try {
            let userCredential;
            if (isNewUser) {
                const invitationType = invitationDetails.invitationType;

                // we need to define the userType of the user based on the invitation type
                let userType = '';
                if (invitationType === 'user_to_org' || invitationType === 'client_to_accountant') {
                    userType = 'User';
                } else {
                    userType = 'Accountant';
                }

                const userRole = invitationDetails.invitee.role;


                userCredential = await createUserWithEmailAndPassword(auth, email, password);
                await setDoc(doc(db, "users", userCredential.user.uid), {
                    createdAt: serverTimestamp(),
                    email: userCredential.user.email,
                    name: name,
                    type: userType,
                    role: userRole
                });
            } else {
                userCredential = await signInWithEmailAndPassword(auth, email, password);
            }

            await acceptInvitation(userCredential.user);
        } catch (error) {
            console.error("Error in authentication:", error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const handleGoogleSignIn = async () => {
        setError(null);
        setIsLoading(true);
        try {
            const userCredential = await authHandleGoogleSignIn();
            await acceptInvitation(userCredential.user);
        } catch (error) {
            console.error("Error signing in with Google:", error);
            setError("Failed to sign in with Google. Please try again.");
            setIsLoading(false);
        }
    };

    const acceptInvitation = async (user) => {
        try {
            const newToken = await user.getIdToken();
            setToken(newToken);
    
            const invitationId = new URLSearchParams(location.search).get('id');
            
            const response = await fetch(`${BACKEND_API_URL}/api/accept-invitation`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${newToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ invitationId })
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error in accepting invitation:", errorData);
                throw new Error('Failed to accept invitation');
            }
    
            const result = await response.json();
            console.log("Invitation accepted successfully:", result.message);
    
            // Force a refresh of the ID token
            await auth.currentUser.getIdToken(true);
    
            // Fetch the updated user data
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                
                // Determine user type and set appropriate flags
                const userType = userData.type;
                localStorage.setItem('invitationAccepted', 'true');
                localStorage.setItem('userType', userType);

                // Update auth state and navigate based on user type
                await refreshAuthState({
                    ...user,
                    ...userData,
                    organization: userData.organizationId ? { id: userData.organizationId } : null
                });

                switch(userData.type) {
                    case 'Accountant':
                        navigate('/accountant-dashboard');
                        break;
                    case 'User':
                        navigate('/dashboard');
                        break;
                    default:
                        navigate('/dashboard');
                }

            } else {
                throw new Error('User document not found after accepting invitation');
            }
        } catch (error) {
            console.error("Error in accepting invitation:", error);
            setError("Failed to accept invitation. Please try again.");
            setIsLoading(false);
        }
    };

    
    return (
        <div className="accept-invitation-container">
            <div className="accept-invitation-form">
                <img src={sumaLogo} alt="Suma Logo" className="suma-logo" />
                <div className="accept-invitation-header">
                    {renderInvitationDetails()}
                    <h1>{isNewUser ? 'Create Account' : 'Sign In'}</h1>
                    
                </div>

                <form onSubmit={handleSubmit}>
                    {isNewUser && (
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input 
                                type="text" 
                                id="name" 
                                placeholder="Enter your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input 
                            type="email" 
                            id="email" 
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input 
                            type="password" 
                            id="password" 
                            placeholder={isNewUser ? "Create a password" : "Enter your password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="submit-btn" disabled={isLoading}>
                        {isLoading ? 'Processing...' : (isNewUser ? 'Create Account' : 'Sign In')}
                    </button>
                </form>

                <div className="google-signin">
                    <button className="google-btn" onClick={handleGoogleSignIn} disabled={isLoading}>
                        <img src={googleIcon} alt="Google Icon" />
                        {isNewUser ? 'Sign up with Google' : 'Sign in with Google'}
                    </button>
                </div>

                {error && <p className="error-message">{error}</p>}

                <p className="toggle-form-link">
                    {isNewUser 
                        ? 'Already have an account?' 
                        : "Don't have an account?"} 
                    <button onClick={() => setIsNewUser(!isNewUser)} className="toggle-form-btn">
                        {isNewUser ? 'Sign In' : 'Create Account'}
                    </button>
                </p>
            </div>
            <div className="accept-invitation-info">
                <div className="testimonial">
                    <p className="testimonial-text">Suma's AI features are a game-changer for my accounting work. The automated categorization and smart invoicing save me countless hours each month. Continuously improving the accuracy and efficiency of my financial management.</p>
                    <div className="testimonial-author">
                        <div className="author-info">
                            <span className="author-name">— Aliah Lane</span>
                            <div className="rating">★★★★★</div>
                        </div>
                        <span className="author-title">Accountant at Gestoría Gesys</span>
                    </div>
                </div>
                <div className="app-preview">
                    <img src={appPreviewImage} alt="App Preview" className="app-preview-image" />
                </div>
            </div>
        </div>
    );
};

export default AcceptInvitation;
