import React, { useState, useEffect, useRef,  useCallback, useImperativeHandle, forwardRef } from "react";
import { Link } from 'react-router-dom'; // Make sure to import Link from react-router-dom
import { useAuth } from "../../auth";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

import { format, subDays, subMonths, startOfWeek, startOfMonth, startOfQuarter, startOfYear, endOfQuarter, endOfYear } from 'date-fns';
import { differenceInMinutes, isToday, isYesterday } from 'date-fns';

import "./TableComponent.css";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import CSVDownloadButton from "../CSVDownloadButton/CSVDownloadButton";
import listDashesIcon from "../../assets/icons/ListDashes.svg"; // Adjust the path as needed
import categoriesIcon from "../../assets/icons/Cardholder.svg"; // Adjust the path as needed
import customersIcon from "../../assets/icons/IdentificationCard.svg"; // Adjust the path as needed
import calendarIcon from "../../assets/icons/Calendar.svg"; // Adjust the path as needed
import arrowDownIcon from "../../assets/icons/ArrowLineDown.svg"; // Adjust the path as needed
import checkboxIcon from "../../assets/icons/Checkbox.svg";
import checkboxOffIcon from "../../assets/icons/CheckboxOff.svg"; // Adjust the path as needed
import listIcon from "../../assets/icons/List.svg"; // Adjust the path as needed
import flowArrowIcon from "../../assets/icons/FlowArrow.svg"; // Adjust the path as needed
import trashIcon from "../../assets/icons/Trash.svg"; // Adjust the path as needed
import folderIcon from "../../assets/icons/FolderIcon.svg"; // Adjust the path as needed
import searchIcon from "../../assets/icons/search-lg.svg"; // Adjust the path as needed
import lineIcon from "../../assets/icons/Line.svg"; // Adjust the path as needed

import { ReactComponent as PDFIcon } from "../../assets/icons/fileTypeIcons/PDF, Type=Default.svg"; // Adjust the path as needed
import { ReactComponent as PNGIcon } from "../../assets/icons/fileTypeIcons/PNG, Type=Default.svg"; // Adjust the path as needed
import { ReactComponent as DocxIcon } from "../../assets/icons/fileTypeIcons/DOCX, Type=Default.svg"; // Adjust the path as needed
import { ReactComponent as JPGIcon } from "../../assets/icons/fileTypeIcons/JPG, Type=Default.svg"; // Adjust the path as needed
import { ReactComponent as HEICIcon } from "../../assets/icons/fileTypeIcons/HEIC, Type=Default.svg"; // Adjust the path as needed
import { ReactComponent as EmptyIcon } from "../../assets/icons/fileTypeIcons/Empty, Type=Default.svg"; // Adjust the path as needed

const TableComponent = forwardRef(({ 
        className, 
        data,
        db, 
        columns,
        currentPage,
        totalPages,
        onPageChange,
        onPerPageChange,
        perPage,
        categoryFilters: initialCategoryFilters,
        stickyColumnId,
        onCSVDownload,
        ViewEditComponent,
        onEditModeChange,
        showEntityFilters = true, // New prop to determine whether to show entity filters
        onRowClick,
        selectedInvoice,
        selectedItem,
        onSortedDataChange,
        allCategories,
        allEntities,
        filters,
        onFilterChange,
        onFinalizeFilters,
        onAddClick, // Add this prop
        showDateFilter = true, // Add this new prop with a default value
        onClose,
        onPrevious,
        onNext,
        onUpdateInvoice,
        organizationId,
        suppliers,
        customers,
        isEditing,
        isInvoicePage = false, // New prop to determine if it's an invoice page
        pageType, // New prop to determine the page type: 'invoice', 'category', 'entity'
        invoiceType, // New prop to determine the invoice type: 'expense', 'revenue'
        onDelete,
        fetchAllData,
        createAnalyticsTrigger,
        pollAnalyticsTriggerStatus,
        onAnalyticsUpdateStart,
        onAnalyticsUpdateEnd,
        organization,
        onRemoveOrganizations,
    }, ref) => {

    // console.log("TableComponent: data", data);


    const { getIdToken } = useAuth();   

    const [visibleColumns, setVisibleColumns] = useState(() => {
        return [stickyColumnId, ...columns
            .filter(col => col.id !== stickyColumnId && col.defaultVisible)
            .map(col => col.id)];
    });


    // New state for column order
    const [columnOrder, setColumnOrder] = useState(() => {
        // console.log("Updating column order");
        return [stickyColumnId, ...columns.filter(col => col.id !== stickyColumnId).map(col => col.id)];
    });
    
    const [selectedItems, setSelectedItems] = useState([]);
    const [sortConfig, setSortConfig] = useState(() => {
        if (!isInvoicePage) {
            return { key: null, direction: 'ascending' };
        } else {
            return { key: 'createdAt', direction: 'descending' };
        }
    });

    const rowRefs = useRef({}); // Store refs for each row
    const scrollContainerRef = useRef(null);
    const [tempCustomDateRange, setTempCustomDateRange] = useState({ startDate: null, endDate: null });
    const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const dropdownRefs = useRef({
        column: null,
        category: null,
        supplier: null,
        date: null
    });

    const [isAllSelected, setIsAllSelected] = useState(false);
    const [activeDateField, setActiveDateField] = useState("startDate");
    const [showActions, setShowActions] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handlePerPageChange = (event) => {
        const newPerPage = Number(event.target.value);
        console.log(`Changing items per page to ${newPerPage}`);
        onPerPageChange(newPerPage);
    };

    const [localFilters, setLocalFilters] = useState(filters);
    const [activeDropdown, setActiveDropdown] = useState(null);

    const applyFilters = useCallback(() => {
        console.log("Applying filters:", localFilters);
        const filtersToApply = { ...localFilters };
        if (!showDateFilter) {
            delete filtersToApply.dateRange;
        }
        onFilterChange(filtersToApply);
        onFinalizeFilters(filtersToApply);
        setActiveDropdown(null);
    }, [localFilters, onFilterChange, onFinalizeFilters, showDateFilter]);


    useEffect(() => {
        const initialFilters = { ...filters };
        if (!showDateFilter) {
            delete initialFilters.dateRange;
        }
        setLocalFilters(initialFilters);
    }, [filters, showDateFilter]);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
      
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const getMobileColumns = useCallback(() => {
        // console.log("Getting Mobile Columns for pageType:", pageType);
        
        switch (pageType) {
            case 'invoice':
                return [
                    { id: 'fileName', label: 'File Name' },
                    { id: 'processedData.total_amount_converted', label: 'Total Amount' }
                ];
            case 'entity':
                return [
                    { id: invoiceType === 'expense' ? 'name' : 'name', 
                      label: invoiceType === 'expense' ? 'Supplier Name' : 'Customer Name' },
                    { id: 'totalAmount', 
                      label: invoiceType === 'expense' ? 'Total Spent' : 'Total Revenue' }
                ];
            case 'category':
                return [
                    { id: 'name', label: 'Category Name' },
                    { id: 'totalAmount', 
                      label: invoiceType === 'expense' ? 'Total Spent' : 'Total Revenue' }
                ];
            case 'accountant-dashboard':
                return [
                    { id: 'name', label: 'Client Name' },
                    { id: 'totalAmount', label: 'Total Amount' }
                ];  
            default:
                console.log("Unknown pageType:", pageType);
                return [];
        }
    }, [pageType]);

    const mobileColumns = getMobileColumns();

    const renderMobileColumnContent = (columnId, item) => {
        switch (columnId) {
          case 'fileName':
            return (
              <div className="mobile-file-info">
                <FileIcon fileType={getFileType(item.fileName)} />
                <span className="file-name">{item.fileName}</span>
              </div>
            );
          case 'supplierName':
          case 'customerName':
          case 'name':
            return <span>{item[columnId]}</span>;
          case 'totalAmount':
          case 'processedData.total_amount_converted':
            let amount;
            if (columnId === 'processedData.total_amount_converted') {
              // For invoices, we need to access the nested property
              amount = parseFloat(item.processedData?.total_amount_converted || 0);
            } else {
              // For other cases, we can directly access the property
              amount = parseFloat(item[columnId] || 0);
            }
            return <span className="mobile-total">€{amount.toFixed(2)}</span>;
          default:
            return null;
        }
      };
    

    const handleCategoryFilter = (categoryId) => {
        setLocalFilters(prev => ({
            ...prev,
            categoryFilters: prev.categoryFilters.includes(categoryId)
                ? prev.categoryFilters.filter(id => id !== categoryId)
                : [...prev.categoryFilters, categoryId]
        }));
    };
    
    const handleEntityFilter = (entityId) => {
        setLocalFilters(prev => ({
            ...prev,
            entityFilters: prev.entityFilters.includes(entityId)
                ? prev.entityFilters.filter(id => id !== entityId)
                : [...prev.entityFilters, entityId]
        }));
    };

    const handleDateFilterChange = useCallback((label, startDate, endDate) => {
        if (!showDateFilter) return; // Don't update date range if showDateFilter is false
    
        console.log("Date Filter Change (label): ", label);
        console.log("Date Filter Change (startDate): ", startDate);
        console.log("Date Filter Change (endDate): ", endDate);
    
        if (label === "Custom dates") {
            setIsCustomDateSelected(true);
            return; // Don't update filters yet
        }

        const updatedFilters = {
            ...localFilters,
            dateRange: { startDate, endDate }
        };
    
        console.log("Updated Filters: ", updatedFilters);
    
        setLocalFilters(updatedFilters);
        onFilterChange(updatedFilters);
        onFinalizeFilters(updatedFilters);
        setActiveDropdown(null);
    }, [localFilters, onFilterChange, onFinalizeFilters, showDateFilter]);
    
    const handleSelectAllDropdown = (filterType) => {
        setLocalFilters(prev => ({
            ...prev,
            [filterType]: filterType === 'categoryFilters' 
                ? allCategories.map(cat => cat.id)
                : allEntities.map(ent => ent.id)
        }));
    };

    const handleDeselectAllDropdown = (filterType) => {
        setLocalFilters(prev => ({
            ...prev,
            [filterType]: []
        }));
    };


    const generateDateOptions = () => {
        const today = new Date();
        return [
            { label: "Today", date: format(today, "d MMM"), startDate: today, endDate: today },
            { label: "Yesterday", date: format(subDays(today, 1), "d MMM"), startDate: subDays(today, 1), endDate: subDays(today, 1) },
            { label: "This week", date: `${format(startOfWeek(today), "d MMM")} - ${format(today, "d MMM")}`, startDate: startOfWeek(today), endDate: today },
            { label: "Last 7 days", date: `${format(subDays(today, 7), "d MMM")} - ${format(today, "d MMM")}`, startDate: subDays(today, 7), endDate: today },
            { label: "This month", date: format(today, "MMM"), startDate: startOfMonth(today), endDate: today },
            { label: "Last 30 days", date: `${format(subDays(today, 30), "d MMM")} - ${format(today, "d MMM")}`, startDate: subDays(today, 30), endDate: today },
            { label: "This quarter", date: `${format(startOfQuarter(today), "MMM")} - ${format(today, "MMM")}`, startDate: startOfQuarter(today), endDate: today },
            { label: "Last quarter", date: `${format(startOfQuarter(subMonths(today, 3)), "MMM")} - ${format(endOfQuarter(subMonths(today, 3)), "MMM")}`, startDate: startOfQuarter(subMonths(today, 3)), endDate: endOfQuarter(subMonths(today, 3)) },
            { label: "This year", date: format(today, "yyyy"), startDate: startOfYear(today), endDate: today },
            { label: "Last year", date: format(subMonths(today, 12), "yyyy"), startDate: startOfYear(subMonths(today, 12)), endDate: endOfYear(subMonths(today, 12)) },
            { label: "All Time", date: "All", startDate: null, endDate: null },
            { label: "Custom dates", date: "", startDate: null, endDate: null }
        ];
    };

    const dateOptions = generateDateOptions();


    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutsideClick = Object.values(dropdownRefs.current).every(
                ref => ref && !ref.contains(event.target)
            );
            if (isOutsideClick) {
                setActiveDropdown(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    
    const handleColumnToggle = (columnId) => {
        if (columnId === stickyColumnId) return; // Prevent toggling the sticky column
        setVisibleColumns(prevColumns => {
            if (prevColumns.includes(columnId)) {
                return prevColumns.filter(col => col !== columnId);
            } else {
                const columnIndex = columns.findIndex(col => col.id === columnId);
                const newColumns = [...prevColumns];
                const insertIndex = newColumns.findIndex((col, index) => {
                    const colIndex = columns.findIndex(c => c.id === col);
                    return colIndex > columnIndex;
                });
                if (insertIndex === -1) {
                    newColumns.push(columnId);
                } else {
                    newColumns.splice(insertIndex, 0, columnId);
                }
                return newColumns;
            }
        });
    };
    
    const getFileType = (fileName) => {
        if (!fileName) {
            console.error('getFileType: fileName is undefined');
            return 'UNKNOWN';
        }
        return fileName.split('.').pop().toUpperCase();
    };


    const handleRowClick = useCallback((item, event) => {
        if (event.target.type === 'checkbox') {
            return; // Only return early for checkboxes
        }
        if (!isEditing && onRowClick) {
            onRowClick(item);
        }
    }, [onRowClick, isEditing]);


    const handleItemSelect = (itemId, event) => {
        event.stopPropagation();
        setSelectedItems((prev) => {
            const updatedSelection = prev.includes(itemId) ? prev.filter((id) => id !== itemId) : [...prev, itemId];
            setShowActions(updatedSelection.length > 0);
            return updatedSelection;
        });
    };

    const handleSelectAll = () => {
        setIsAllSelected(prevIsAllSelected => {
            const newIsAllSelected = !prevIsAllSelected;
            if (newIsAllSelected) {
                setSelectedItems(sortedData.map(item => item.id));
                setShowActions(true);
            } else {
                setSelectedItems([]);
                setShowActions(false);
            }
            return newIsAllSelected;
        });
    };
    
    const handleCSVDownload = async () => {
        setIsDownloading(true);
        try {
            const downloadFilters = {
                categoryFilters: filters.categoryFilters,
                entityFilters: filters.entityFilters,
            };
            if (showDateFilter) {
                downloadFilters.dateRange = filters.dateRange;
            }
            await onCSVDownload({
                type: invoiceType,
                columns: visibleColumns,
                itemIds: selectedItems.length > 0 ? selectedItems : null,
                filters: downloadFilters
            });
        } catch (error) {
            console.error("Failed to download CSV", error);
            showSnackbar('Failed to download CSV', 'error');
        } finally {
            setIsDownloading(false);
        }
    };


    


    const getEntityName = useCallback((entityId) => {
        // console.log("I'm in the getEntityName function");
        // console.log("entityId:", entityId);
        const entity = allEntities.find(e => e.id === entityId);
        // console.log("entity:", entity);
        // console.log("entity.name:", entity.name);
        return entity ? entity.name : 'Unknown';
    }, [allEntities]);

    const getCategoryName = useCallback((categoryId) => {
        // console.log("I'm in the getCategoryName function");
        // console.log("categoryId:", categoryId);
        const category = allCategories?.find(c => c.id === categoryId);
        // console.log("category:", category);
        // console.log("category.name:", category?.name);
        return category ? category.name : 'Unknown Category';
    }, [allCategories]);


    const sortedData = React.useMemo(() => {
       
        let sortedData = [...data].map(invoice => {
            const entityId = invoiceType === 'expense' ? invoice.processedData?.supplier_id : invoice.processedData?.customer_id;
            const entity = allEntities?.find(e => e.id === entityId);
            
            // console.log('Entity:', entity);
            // console.log('Entity categoryId:', entity ? entity?.categoryId : 'No entity found');
            
            const categoryName = getCategoryName(entity ? entity?.categoryId : null);
            // console.log('Category Name:', categoryName);
    
            return {
                ...invoice,
                supplierName: invoiceType === 'expense' ? (entity ? entity.name : 'Unknown') : undefined,
                customerName: invoiceType === 'revenue' ? (entity ? entity.name : 'Unknown') : undefined,
                supplierCategory: invoiceType === 'expense' ? categoryName : undefined,
                customerCategory: invoiceType === 'revenue' ? categoryName : undefined,
            };
        });

        // Always keep 'new' status invoices at the top
        const newInvoices = sortedData.filter(item => item.status === 'new');
        const otherInvoices = sortedData.filter(item => item.status !== 'new');
    
        // Apply sorting to non-new invoices
        if (sortConfig.key) {
            const columnToSort = columns.find(col => col.id === sortConfig.key);
            if (columnToSort && columnToSort.sortable) {
                otherInvoices.sort((a, b) => {
                    const getValue = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);
                    let aValue = getValue(a, sortConfig.key);
                    let bValue = getValue(b, sortConfig.key);
    
                    // Handle special cases (dates, numbers, etc.)
                    if (sortConfig.key === 'createdAt' || sortConfig.key.includes('date')) {
                        aValue = new Date(aValue);
                        bValue = new Date(bValue);
                    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                        // No conversion needed for numbers
                    } else {
                        aValue = aValue?.toString().toLowerCase();
                        bValue = bValue?.toString().toLowerCase();
                    }
    
                    if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                });
            }
        } else {
            console.log("No sorting applied");
        }
    
        const result = [...newInvoices, ...otherInvoices];
        
        return result;
    }, [data, sortConfig, columns, getCategoryName, allEntities, invoiceType]);


    useEffect(() => {
        if (onSortedDataChange) {
            onSortedDataChange(sortedData);
        }
    }, [sortedData, onSortedDataChange]);

    const handleSort = (columnId) => {
        setSortConfig(prevConfig => {
            const newConfig = {
                key: columnId,
                direction: prevConfig.key === columnId && prevConfig.direction === 'ascending' ? 'descending' : 'ascending'
            };
           
            return newConfig;
        });
    }
    
    useEffect(() => {
        if (onSortedDataChange) {
            onSortedDataChange(sortedData);
        }
    }, [sortedData, onSortedDataChange]);

    const renderColumnContent = (columnId, item) => {

        if (isMobile) {
            switch (columnId) {
              case 'fileName':
                return (
                  <div className="mobile-file-info">
                    <FileIcon fileType={getFileType(item.fileName)} />
                    <div className="file-name">{item.fileName}</div>
                  </div>
                );
              case 'processedData.total_amount_converted':
                const amount = parseFloat(item.processedData?.total_amount_converted || 0);
                return <span className="mobile-total">€{amount.toFixed(2)}</span>;
              default:
                return null;
            }
          }

        const getValue = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);
        const value = getValue(item, columnId);
        // console.log("value:", value);   
        
        if (!value && value !== 0 && value !== '') return null;

        if (columnId === 'name') {  // Assuming 'name' is the id for the category name column
            return (
                <div className="icon-wrapper category-name-cell">
                    <img src={folderIcon} alt="Folder" className="file-icon-table" />
                    <div className="category-details">
                        <span className="category-name">{value}</span>
                        {item.id && <span className="category-id">#{item.id}</span>}
                    </div>
                </div>
            );
        } else if (columnId === 'createdAt' || columnId === 'updatedAt' || columnId === 'added' || columnId === 'lastUpdate') {
            const createdDate = value?.seconds ? new Date(value?.seconds * 1000) : new Date(value);
            const now = new Date();
            const minutesDiff = differenceInMinutes(now, createdDate);
    
            if (minutesDiff < 1) {
                return "A few seconds ago";
            } else if (minutesDiff < 60) {
                return ` ${minutesDiff} minute${minutesDiff > 1 ? 's' : ''} ago`;
            } else if (isToday(createdDate)) {
                return "Today";
            } else if (isYesterday(createdDate)) {
                return "Yesterday";
            } else {
                return format(createdDate, "MMM d, yyyy");
            }
        } else if (columnId === 'lastInvoiceDate') {
            const date = value.seconds ? new Date(value.seconds * 1000) : new Date(value);
            return isNaN(date) ? 'N/A' : format(date, "MMM d, yyyy");
        } else if (columnId.includes('date')) {
            // Handle other date fields as before
            const date = value?.seconds ? new Date(value?.seconds * 1000) : new Date(value);
            return isNaN(date) ? 'Invalid Date' : format(date, "MMM d, yyyy");
        } else if (columnId === 'processedData.supplier_category' || columnId === 'processedData.customer_category') {
            return <span>{value}</span>;
        } else if (columnId === 'processedData.exchange_rate') {
            return Number(value).toFixed(2);
        } else if (columnId === 'taxNumber') {
            return value?.toString();
        } else if (columnId.includes('amount') || columnId.includes('tax') || columnId.includes('subtotal')) {
            return `€${parseFloat(value).toFixed(2)}`;
        } else if (columnId === 'processedData.vat_tax_percentage') {
            return `${value}%`;
        } else if (columnId === 'url') {
            return <a href={value} target="_blank" rel="noopener noreferrer">View File</a>;
        } else if (columnId === 'totalAmount' || columnId === 'totalExpenses' || columnId === 'totalRevenue') {
            // Format totalAmount to a maximum of 2 decimal places
            return `€${parseFloat(value).toFixed(2)}`;
        } else if (columnId === 'supplierName' || columnId === 'customerName') {
            // console.log(`Handling supplier/customer ID. Value: ${value}`);

            // The value should already be the entity name, but let's double-check
            const entityId = item?.processedData ? (item.processedData.supplier_id || item.processedData?.customer_id) : null;
            const entityName = entityId ? getEntityName(entityId) : value;
            // console.log(`Entity ID: ${value}, Entity Name: ${entityName}`);
            return (
                <div>
                    <div>{entityName}</div>
                    {/* {entityId && <div className="entity-id">ID: {entityId}</div>} */}
                </div>
            );
        } else if (columnId === 'supplierCategory' || columnId === 'customerCategory') {
            return value || 'Unknown Category';
        } else {
            return value?.toString();
        }
    };
    
    const FileIcon = ({ fileType }) => {
        switch (fileType.toLowerCase()) {
            case 'pdf':
                return <PDFIcon className="file-icon-table" />;
            case 'png':
                return <PNGIcon className="file-icon-table" />;
            case 'docx':
                return <DocxIcon className="file-icon-table" />;
            case 'jpeg':
                return <JPGIcon className="file-icon-table" />;
            case 'jpg':
                return <JPGIcon className="file-icon-table" />;
            case 'heic':
                return <HEICIcon className="file-icon-table" />;
         
            default:
                return <EmptyIcon className="file-icon-table" />; // add a default icon if necessary
        }
    };    

    const getDateRangeDisplay = () => {
        if (isMobile) {
          return "Date Range";
        }
        return filters.dateRange && filters.dateRange.startDate && filters.dateRange.endDate
          ? `${format(filters.dateRange.startDate, 'd MMM yyyy')} - ${format(filters.dateRange.endDate, 'd MMM yyyy')}`
          : "Date Range";
      };


    const handleCustomDateChange = (date) => {
        setTempCustomDateRange(prev => {
            let newRange = { ...prev };
            if (activeDateField === "startDate") {
                newRange.startDate = date;
                if (newRange.endDate && date > newRange.endDate) {
                    newRange.endDate = null;
                }
            } else {
                newRange.endDate = date;
                if (newRange.startDate && date < newRange.startDate) {
                    newRange.startDate = null;
                }
            }
            return newRange;
        });
    
        setActiveDateField(prev => prev === "startDate" ? "endDate" : "startDate");
    };

    const applyCustomDateRange = () => {
        if (tempCustomDateRange.startDate && tempCustomDateRange.endDate) {
            const updatedFilters = {
                ...localFilters,
                dateRange: {
                    startDate: tempCustomDateRange.startDate,
                    endDate: tempCustomDateRange.endDate
                }
            };
    
            setLocalFilters(updatedFilters);
            onFilterChange(updatedFilters);
            onFinalizeFilters(updatedFilters);
    
            setActiveDropdown(null);  // Close the dropdown
            setIsCustomDateSelected(false); // Reset to default after applying custom range
    
            console.log("Applied custom date range:", updatedFilters.dateRange);
        } else {
            console.error("Both start date and end date must be selected");
        }
    };

    const formatDate = (date) => {
        if (!date || !(date instanceof Date) || isNaN(date)) {
            console.error("Invalid date:", date);
            return "Invalid date";
        }
        return format(date, "dd/MM/yyyy");
    };

    const toggleDropdown = (dropdown) => {
        setActiveDropdown(prev => prev === dropdown ? null : dropdown);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(columnOrder);
        const [reorderedItem] = items.splice(result.source.index+1, 1);
        items.splice(result.destination.index+1, 0, reorderedItem);

        // Ensure the sticky column stays at the beginning
        const stickyColumnIndex = items.indexOf(stickyColumnId);
        if (stickyColumnIndex !== 0) {
            items.splice(stickyColumnIndex, 1);
            items.unshift(stickyColumnId);
        }

        setColumnOrder(items);
    };



    const handleMoveToRevenue = () => {
        // Implement the logic for moving selected invoices to revenue
        console.log("Move to Revenue clicked");
    };

    const handleDeleteClick = () => {
        setShowDeleteModal(true);
    };




    const handleDeleteOrRemove = async () => {
        setIsDeleting(true);
        try {

            if (pageType === 'accountant-dashboard') {
                await onRemoveOrganizations(selectedItems);
            } else {

                const token = await getIdToken();
                // Create analytics trigger
                console.log("Creating analytics trigger");

                // Map pageType to changeType
                let changeType;
                switch(pageType) {
                    case 'invoice':
                        changeType = 'invoice';
                        break;
                    case 'entity':
                        changeType = 'entity';
                        break;
                    case 'category':
                        changeType = 'category';
                        break;
                    default:
                        throw new Error(`Invalid pageType: ${pageType}`);
                }

                console.log("db:", db);
                console.log("organization:", organization);
                console.log("changeType:", changeType);
                console.log("selectedItems.length:", selectedItems.length);

                console.log("Creating analytics trigger");
                const triggerId = await createAnalyticsTrigger(db, organization, changeType, selectedItems.length, 'delete');
                console.log("Analytics trigger created:", triggerId);

                // Perform deletion
                await onDelete(selectedItems, triggerId);
                // Start polling for analytics completion
                onAnalyticsUpdateStart();
                await pollAnalyticsTriggerStatus(
                    triggerId,
                    getIdToken,
                    {
                        onActionCompleted: () => {
                            console.log("Delete action completed");
                        },
                        onAnalyticsCompleted: async () => {
                            await fetchAllData();

                            if (onAnalyticsUpdateEnd) {
                                onAnalyticsUpdateEnd();
                            }
                        },
                        onAnalyticsError: (error) => {
                            console.error("Analytics update failed:", error);
                            if (onAnalyticsUpdateEnd) {
                                onAnalyticsUpdateEnd();
                            }
                        }
                    },
                    token
                );
            }

            setSelectedItems([]);
            setShowActions(false);
            setIsAllSelected(false);
            showSnackbar(`Successfully deleted ${selectedItems.length} item(s)`, 'success');
        } catch (error) {
            console.error("Failed to delete items", error);
            showSnackbar('Failed to delete items: ' + error.message, 'error');
            if (onAnalyticsUpdateEnd) {
                onAnalyticsUpdateEnd();
            }
        } finally {
            setIsDeleting(false);
            setShowDeleteModal(false);
        }
    };

    const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, itemCount, isDeleting, pageType }) => {
        if (!isOpen) return null;
    
        const isAccountantDashboard = pageType === 'accountant-dashboard';
        const action = isAccountantDashboard ? 'remove' : 'delete';
        const actionPresentTense = isAccountantDashboard ? 'Remove' : 'Delete';
        const actionPastTense = isAccountantDashboard ? 'Removed' : 'Deleted';
    
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <h2>Confirm {actionPresentTense}</h2>
                    <p>Are you sure you want to {action} {itemCount} selected item(s)?</p>
                    <div className="modal-actions">
                        <button onClick={onClose} disabled={isDeleting}>Cancel</button>
                        <button onClick={onConfirm} disabled={isDeleting}>
                            {isDeleting ? `${actionPresentTense}...` : `Confirm ${actionPresentTense}`}
                        </button>
                    </div>
                </div>
            </div>
        );
    };


    // const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, itemCount, isDeleting, pageType }) => {
    //     if (!isOpen) return null;
    
    //     return (
    //         <div className="modal-overlay">
    //             <div className="modal-content">
    //                 <h2>Confirm Deletion</h2>
    //                 <p>
    //                     {pageType === 'accountant-dashboard'
    //                         ? `Are you sure you want to remove ${itemCount === 1 ? 'this client' : 'these clients'}?`
    //                         : `Are you sure you want to delete ${itemCount} selected item(s)?`
    //                     }
    //                 </p>
    //                 <div className="modal-actions">
    //                     <button onClick={onClose} disabled={isDeleting}>Cancel</button>
    //                     <button onClick={onConfirm} disabled={isDeleting}>
    //                         {isDeleting ? (pageType === 'accountant-dashboard' ? "Removing..." : "Deleting...") : (pageType === 'accountant-dashboard' ? "Confirm Remove" : "Confirm Delete")}
    //                     </button>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    const handleRemoveOrganizations = async () => {
        setIsDeleting(true);
        try {
          // Call a new function in AccountantDashboard to remove organizations
          await onRemoveOrganizations(selectedItems);
          setSelectedItems([]);
          setShowActions(false);
          setIsAllSelected(false);
          showSnackbar(`Successfully removed ${selectedItems.length} client(s)`, 'success');
        } catch (error) {
          console.error("Failed to remove organizations:", error);
          showSnackbar('Failed to remove organizations: ' + error.message, 'error');
        } finally {
          setIsDeleting(false);
          setShowDeleteModal(false);
        }
      };



    const Snackbar = ({ open, message, severity, onClose }) => {
        useEffect(() => {
            if (open) {
                console.log("Snackbar opened with message:", message);
                const timer = setTimeout(() => {
                    console.log("Closing snackbar after timeout");
                    onClose();
                }, 3000);
    
                return () => clearTimeout(timer);
            }
        }, [open, message, onClose]);
        
        if (!open) return null;
    
        return (
            <div className={`snackbar ${severity}`}>
                {message}
                <button onClick={onClose}>×</button>
            </div>
        );
    };


    const showSnackbar = (message, severity) => {
        console.log("Showing snackbar:", message, severity);
        setSnackbar({ open: true, message, severity });
    };
    
    const closeSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };


    useImperativeHandle(ref, () => ({
        scrollToRow: (invoiceId) => {
            if (rowRefs.current[invoiceId]) {
                rowRefs.current[invoiceId].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }
        }
    }), []);

    
    const renderEmptyState = () => {
        let title, description, buttonText, buttonUrl;

        console.log("I'm rendering the empty state");
        console.log("pageType:", pageType);
        console.log("invoiceType:", invoiceType);
        console.log("onAddClick:", onAddClick);
        
        switch (pageType) {
            case 'invoice':
                title = `No ${invoiceType} invoices found`;
                description = `Please try again or add a new ${invoiceType} invoice.`;
                buttonText = `+ Add new ${invoiceType} invoice`;
                break;
            case 'entity':
                if (invoiceType === 'expense') {
                    title = "No suppliers found";
                    description = "Please try again or add a new supplier.";
                    buttonText = "+ Add new supplier";
                    buttonUrl = "/suppliers/new";
                } else {
                    title = "No customers found";
                    description = "Please try again or add a new customer.";
                    buttonText = "+ Add new customer";
                    buttonUrl = "/customers/new";
                }
                break;
            case 'category':
                if (invoiceType === 'expense') {
                    title = "No supplier categories found";
                    description = "Please try again or add a new supplier category.";
                    buttonText = "+ Add new supplier category";
                    buttonUrl = "/supplier-categories/new";
                } else {
                    title = "No customer categories found";
                    description = "Please try again or add a new customer category.";
                    buttonText = "+ Add new customer category";
                    buttonUrl = "/customer-categories/new";
                }
                break;
            case 'accountant-dashboard':
                title = "No clients found";
                description = "Please try again or add a new client.";
                buttonText = "+ Add new client";
                break;
            default:
                title = "No items found";
                description = "Please try again or add a new item.";
                buttonText = "+ Add new item";
        }
    
        return (
            <div className="empty-state-container">
                <div className="circular-lines">
                    <img src={lineIcon} alt="Circular line" className="circular-line line-416" />
                    <img src={lineIcon} alt="Circular line" className="circular-line line-352" />
                    <img src={lineIcon} alt="Circular line" className="circular-line line-288" />
                    <img src={lineIcon} alt="Circular line" className="circular-line line-224" />
                    <img src={lineIcon} alt="Circular line" className="circular-line line-160" />
                    <img src={lineIcon} alt="Circular line" className="circular-line line-96" />
                </div>
                <div className="search-icon-container">
                    <img src={searchIcon} alt="Search" className="search-icon" />
                </div>
                <h2 className="empty-state-title">{title}</h2>
                <p className="empty-state-description">{description}</p>
                {pageType === 'invoice' || pageType === 'accountant-dashboard' ? (
                    <button className="add-new-invoice-button" onClick={onAddClick}>{buttonText}</button>
                ) : (
                    <Link to={buttonUrl} className="add-new-invoice-button">{buttonText}</Link>
                )}
            </div>
        );
    };

  
    return (
        <div className={`table-container ${className}`}>
            <div className="filter-section ">
                <div className="table-header-left">
                    {!isMobile && (
                    <div className="header-icons">
                        <div className="dropdown" ref={el => dropdownRefs.current.column = el}>
                            {pageType !== 'accountant-dashboard' && (
                                <button className="icon-button" onClick={() => toggleDropdown('column')}>
                                    <img src={listDashesIcon} alt="List Icon" />
                                </button>
                            )}
                            {activeDropdown === 'column' && (
                                <div className="dropdown-content dropdown-content-scrollable">

                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="columns">
                                            {(provided, snapshot) => (
                                                <div
                                                className={`dropdown-scroll-area ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                                                {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >

                                                    
                                                        {/* Sticky column (not draggable) */}
                                                        <label className="dropdown-item checkbox-label">
                                                            <input
                                                                type="checkbox"
                                                                checked
                                                                disabled
                                                            />
                                                            <span className="checkbox-custom">
                                                                <img className="checkbox-icon" src={checkboxIcon} alt="Checkbox" />
                                                                <img className="checkbox-off-icon" src={checkboxOffIcon} alt="Checkbox Off" />
                                                            </span>
                                                            {columns.find(col => col.id === stickyColumnId).label}
                                                            <img src={listIcon} alt="List Icon" className="list-icon" style={{ visibility: 'hidden' }} />
                                                        </label>
                                                
                                                        {/* Other columns (draggable) */}
                                                        {columnOrder.filter(columnId => columnId !== stickyColumnId).map((columnId, index) => {
                                                            const column = columns.find(col => col.id === columnId);
                                                            return (
                                                                <Draggable key={columnId} draggableId={columnId} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <label
                                                                            className={`dropdown-item checkbox-label draggable-item ${snapshot.isDragging ? 'dragging' : ''}`}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={visibleColumns.includes(columnId)}
                                                                                onChange={() => handleColumnToggle(columnId)}
                                                                            />
                                                                            <span className="checkbox-custom">
                                                                                <img className="checkbox-icon" src={checkboxIcon} alt="Checkbox" />
                                                                                <img className="checkbox-off-icon" src={checkboxOffIcon} alt="Checkbox Off" />
                                                                            </span>
                                                                            {column.label}
                                                                            <img src={listIcon} alt="List Icon" className="list-icon" />
                                                                        </label>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        })}
                                                    
                                                        {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                                
                            )}
                        </div>  
                    </div>
                    )}

                    {!showActions && isInvoicePage && ( // Only show CSV button on invoice pages
                    <CSVDownloadButton 
                        onClick={handleCSVDownload} 
                        isDownloading={isDownloading}
                        showIcon={true}
                        className="normal-csv-button"
                    />
                    )}

                </div> 
                <div className="header-right">
                    {showActions && (
                        <div className="action-buttons-container">
                            <div className="action-buttons">
                                {/* {invoiceType === 'expense' && pageType !== 'accountant-dashboard' && (
                                    <button className="action-button" onClick={handleMoveToRevenue}>
                                    <img src={flowArrowIcon} alt="Move to Revenue" className="button-icon" />
                                    Move to Revenue
                                    </button>
                                )} */}
                                {isInvoicePage && ( // Only show CSV button on invoice pages
                                    <CSVDownloadButton 
                                        onClick={handleCSVDownload} 
                                        isDownloading={isDownloading}
                                        showIcon={true}
                                        className="action-csv-button"
                                        iconColor="invert(20%) sepia(10%) saturate(2076%) hue-rotate(86deg) brightness(90%) contrast(86%)"
                                    />
                                )}
                                {pageType !== 'accountant-dashboard' && (
                                    <button className="action-button" onClick={handleDeleteClick}>
                                        <img src={trashIcon} alt="Delete" className="button-icon" />
                                        Delete
                                    </button>
                                )}
                                {pageType === 'accountant-dashboard' && (
                                    <button className="action-button" onClick={handleDeleteClick}>
                                        <img src={trashIcon} alt="Remove" className="button-icon" />
                                        Remove
                                    </button>
                                )}
                                <DeleteConfirmationModal 
                                    isOpen={showDeleteModal}
                                    onClose={() => setShowDeleteModal(false)}
                                    onConfirm={handleDeleteOrRemove}
                                    itemCount={selectedItems.length}
                                    isDeleting={isDeleting}
                                    pageType={pageType}
                                />
                                <Snackbar 
                                    open={snackbar.open}
                                    message={snackbar.message}
                                    severity={snackbar.severity}
                                    onClose={closeSnackbar}
                                />
                            </div>
                        </div>
                    )}  
                    {!showActions && (
                    <>  
    
                        <div className="header-filters">
                            {showEntityFilters && (
                                <>
                                <div className="filter-dropdown" 
                                    ref={el => dropdownRefs.current.category = el}
                                >
                                    <button className="filter-button" onClick={() => toggleDropdown('category')}>
                                        <div className="filter-button-content">
                                            <img src={categoriesIcon} alt="Categories Icon" />
                                            <span>{localFilters.categoryFilters.length > 0 ? `Categories (${localFilters.categoryFilters.length})` : "Categories"}</span>
                                        </div>
                                        <img src={arrowDownIcon} alt="Arrow Down Icon" />
                                    </button>
                                    {activeDropdown === 'category' && (
                                        <div className="dropdown-content dropdown-content-scrollable">
                                            <div className="dropdown-scroll-area">
                                                {Array.isArray(allCategories) && allCategories.length > 0 ? (
                                                    allCategories.map((category) => (
                                                        <label key={category.id} className="dropdown-item checkbox-label">
                                                            <input
                                                                type="checkbox"
                                                                checked={localFilters.categoryFilters.includes(category.id)}
                                                                onChange={() => handleCategoryFilter(category.id)}
                                                                                                            />
                                                            <span className="checkbox-custom">
                                                                <img className="checkbox-icon" src={checkboxIcon} alt="Checkbox" />
                                                                <img className="checkbox-off-icon" src={checkboxOffIcon} alt="Checkbox Off" />
                                                            </span>
                                                            {category.name}
                                                        </label>
                                                    ))
                                                ) : (
                                                    <div>No categories available</div>
                                                )}
                                            </div>
                                            <div class="action-buttons">
                                                <button class="action-button" onClick={() => applyFilters()}>Apply Filters</button>
                                                <button class="action-button" onClick={() => handleSelectAllDropdown('categoryFilters')}>Select All</button>
                                                <button class="action-button" onClick={() => handleDeselectAllDropdown('categoryFilters')}>Deselect All</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                                
                                <div className="filter-dropdown" 
                                    ref={el => dropdownRefs.current.entity = el}
                                    >
                                    <button className="filter-button" onClick={() => toggleDropdown('entity')}>
                                        <div className="filter-button-content">
                                            <img src={customersIcon} alt="Entities Icon" />
                                            {localFilters.entityFilters.length > 0
                                                ? `${invoiceType === 'expense' ? 'Suppliers' : 'Customers'} (${localFilters.entityFilters.length})` 
                                                : invoiceType === 'expense' ? 'Suppliers' : 'Customers'}
                                        </div>
                                        <img src={arrowDownIcon} alt="Arrow Down Icon" />
                                    </button>
                                    {activeDropdown === 'entity' && (
                                        <div className="dropdown-content dropdown-content-scrollable">
                                            <div className="dropdown-scroll-area">
                                                {Array.isArray(allEntities) && allEntities.length > 0 ? (
                                                    allEntities.map((entity) => (
                                                        <label key={entity.id} className="dropdown-item checkbox-label">
                                                            <input
                                                                type="checkbox"
                                                                checked={localFilters.entityFilters.includes(entity.id)}
                                                                onChange={() => handleEntityFilter(entity.id)}
                                                            />
                                                            <span className="checkbox-custom">
                                                                <img className="checkbox-icon" src={checkboxIcon} alt="Checkbox" />
                                                                <img className="checkbox-off-icon" src={checkboxOffIcon} alt="Checkbox Off" />
                                                            </span>
                                                            {entity.name}
                                                        </label>
                                                    ))
                                                ) : (
                                                    <div>No entities available</div>
                                                )}
                                            </div>
                                            <div class="action-buttons">
                                                <button class="action-button" onClick={() => applyFilters()}>Apply Filters</button>
                                                <button class="action-button" onClick={() => handleSelectAllDropdown('entityFilters')}>Select All</button>
                                                <button class="action-button" onClick={() => handleDeselectAllDropdown('entityFilters')}>Deselect All</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                </>
                            )}
                            {showDateFilter && ( // Only render date filter if showDateFilter is true
                                <div className="filter-dropdown" ref={el => dropdownRefs.current.date = el}>
                                    <button className="filter-button" onClick={() => toggleDropdown('date')}>
                                        <div className="filter-button-content">
                                            <img src={calendarIcon} alt="Calendar Icon" />
                                            {/* {filters.dateRange && filters.dateRange.startDate && filters.dateRange.endDate
                                            ? `${format(filters.dateRange.startDate, 'd MMM yyyy')} - ${format(filters.dateRange.endDate, 'd MMM yyyy')}`
                                            : "Date Range"} */}
                                                <span>{getDateRangeDisplay()}</span>
                                        </div>
                                        <img src={arrowDownIcon} alt="Arrow Down Icon" />
                                    </button>

                                    {activeDropdown === 'date' && (
                                        <div className={`dropdown-content dropdown-content-dates ${isCustomDateSelected ? 'custom-dates-dropdown' : ''}`}>
                                            {isCustomDateSelected ? (
                                                <div className="custom-date-picker">
                                                    <div className="date-inputs">
                                                        <button 
                                                            className={`date-input-button ${activeDateField === "startDate" ? "active" : ""}`} 
                                                            onClick={() => setActiveDateField("startDate")}
                                                        >
                                                            From: {tempCustomDateRange.startDate ? formatDate(tempCustomDateRange.startDate) : "Select Start Date"}
                                                        </button>
                                                        <button 
                                                            className={`date-input-button ${activeDateField === "endDate" ? "active" : ""}`} 
                                                            onClick={() => setActiveDateField("endDate")}
                                                        >
                                                            To: {tempCustomDateRange.endDate ? formatDate(tempCustomDateRange.endDate) : "Select End Date"}
                                                        </button>
                                                    </div>
                                                    <DatePicker
                                                        selected={activeDateField === "startDate" ? tempCustomDateRange.startDate : tempCustomDateRange.endDate}
                                                        onChange={handleCustomDateChange}
                                                        startDate={tempCustomDateRange.startDate}
                                                        endDate={tempCustomDateRange.endDate}
                                                        selectsStart={activeDateField === "startDate"}
                                                        selectsEnd={activeDateField === "endDate"}
                                                        minDate={activeDateField === "endDate" ? tempCustomDateRange.startDate : null}
                                                        maxDate={activeDateField === "startDate" ? tempCustomDateRange.endDate : null}
                                                        inline
                                                    />
                                                    <button 
                                                        className="apply-button" 
                                                        onClick={applyCustomDateRange}
                                                        disabled={!tempCustomDateRange.startDate || !tempCustomDateRange.endDate}
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            ) : (
                                                dateOptions.map((option, index) => (
                                                    <label
                                                        key={option.label || `date-${index}`}
                                                        className={`dropdown-item dropdown-item-dates checkbox-label`}
                                                        onClick={() => handleDateFilterChange(option.label, option.startDate, option.endDate)}
                                                    >
                                                        <span>
                                                            <img src={calendarIcon} alt="Calendar Icon" className="date-icon" />
                                                            {option.label}
                                                        </span>
                                                        <span className="date-display">{option.date}</span>
                                                    </label>
                                                ))
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                    )}
                </div> 
            </div>
            <div className="table-content">
                <div className="table-wrapper" ref={scrollContainerRef}>
                    {sortedData.length > 0 ? (
                        isMobile ? (
                            <div className="mobile-table">
                                <div className="mobile-table-header">
                                {mobileColumns.map((column) => (
                                    <div key={column.id} className={`mobile-header-cell ${column.id}`}>{column.label}</div>
                                ))}
                                </div>
                                {sortedData.map((item) => (
                                <div key={item.id} className="mobile-row" onClick={(event) => handleRowClick(item, event)}>
                                    {mobileColumns.map((column) => (
                                    <div key={column.id} className={`mobile-cell ${column.id}`}>
                                        {renderMobileColumnContent(column.id, item)}
                                    </div>
                                    ))}
                                </div>
                                ))}
                            </div>
                        ) : (
                            <table className="table-header">
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                checked={isAllSelected}
                                                onChange={handleSelectAll}
                                            />
                                        </th>                    
                                        {columnOrder
                                            .filter(columnId => visibleColumns.includes(columnId))
                                            .map(columnId => {
                                                const column = columns.find(col => col.id === columnId);
                                                return (
                                                    <th key={column.id} onClick={() => handleSort(column.id)}>
                                                        {column.label}
                                                        {sortConfig.key === column.id && (
                                                            <img
                                                                src={arrowDownIcon}
                                                                alt="Arrow Icon"
                                                                className={`table-header-icon ${sortConfig.direction === 'ascending' ? 'table-header-icon-asc' : ''}`}
                                                            />
                                                        )}
                                                    </th>
                                                );
                                            })}
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>

                                {sortedData.map((item) => {
                                    const fileType = item.fileName ? getFileType(item.fileName) : null;
                                    return (
                                        <tr 
                                            key={item.id} 
                                            onClick={(event) => handleRowClick(item, event)}
                                            className={
                                                (selectedInvoice && item.id === selectedInvoice.id) || 
                                                (selectedItem && item.id === selectedItem.id) 
                                                    ? "selected-row" 
                                                    : ""
                                            }
                                            ref={el => {
                                                if (el) {
                                                    rowRefs.current[item.id] = el;
                                                }
                                            }}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedItems.includes(item.id)}
                                                    onChange={(event) => handleItemSelect(item.id, event)}
                                                />
                                            </td>
            
                                            {columnOrder
                                                .filter(columnId => visibleColumns.includes(columnId))
                                                .map(columnId => {
                                                    const column = columns.find(col => col.id === columnId);
                                                    return (
                                                        <td key={column.id} className={column.className}>
                                                            {column.id === 'fileName' && item.fileName ? (
                                                                <div className="icon-wrapper">
                                                                    <FileIcon fileType={fileType} />
                                                                    <div className="item-details">
                                                                        <span className="file-name">{item.fileName}</span>
                                                                        {item.fileSize && (
                                                                            <span className="file-size">
                                                                                {(item.fileSize / 1024 / 1024).toFixed(2)} MB
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                renderColumnContent(column.id, item)
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}

                                </tbody>
                            </table>
                        )
                    ) : (
                        renderEmptyState()
                    )}
                </div>  
            </div>     
            <div className="table-spacer"></div>                   
            <div className="table-pagination">
                <button 
                    className="pagination-button" 
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    ‹
                </button>
                {[...Array(totalPages).keys()].map((page) => (
                    <button 
                        key={page + 1}
                        className={`pagination-button ${currentPage === page + 1 ? 'active' : ''}`}
                        onClick={() => onPageChange(page + 1)}
                    >
                        {page + 1}
                    </button>
                ))}
                <button 
                    className="pagination-button"
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    ›
                </button>
                <div className="filter-dropdown per-page-dropdown">
                    <button className="filter-button" onClick={() => toggleDropdown('perPage')}>
                        {perPage} per page
                        <img src={arrowDownIcon} alt="Arrow Down Icon" className={activeDropdown === 'perPage' ? 'rotated' : ''} />
                    </button>
                    {activeDropdown === 'perPage' && (
                        <div className="dropdown-content dropdown-content-up">
                            {[20, 50, 100].map((value) => (
                                <label key={value} className="dropdown-item">
                                    <input
                                        type="radio"
                                        value={value}
                                        checked={perPage === value}
                                        onChange={() => {
                                            handlePerPageChange({ target: { value } });
                                            setActiveDropdown(null);
                                        }}
                                    />
                                    {value} per page
                                </label>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {selectedItem && ViewEditComponent && (
                <ViewEditComponent 
                    invoice={selectedItem} 
                    invoiceType={invoiceType}
                    allEntities={allEntities}
                    allCategories={allCategories}
                    onEditModeChange={onEditModeChange}
                    onClose={onClose}
                    onPrevious={onPrevious}
                    onNext={onNext}
                    onUpdateInvoice={onUpdateInvoice}
                    organizationId={organizationId}
                    db={db}
                    suppliers={suppliers}
                    customers={customers}
                    fetchAllData={fetchAllData}
                    isEditing={isEditing}

                />
            )}
        <Snackbar 
            open={snackbar.open}
            message={snackbar.message}
            severity={snackbar.severity}
            onClose={closeSnackbar}
        />
        </div>
    );
    });

TableComponent.displayName = 'TableComponent';

TableComponent.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired,
    categoryField: PropTypes.string.isRequired,
    entityField: PropTypes.string.isRequired,   
    // setData: PropTypes.func.isRequired,
    db: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        className: PropTypes.string,
        fixed: PropTypes.bool,
        defaultVisible: PropTypes.bool,
        sortable: PropTypes.bool
    })).isRequired,
    stickyColumnId: PropTypes.string.isRequired,
    filters: PropTypes.object,   
    onCSVDownload: PropTypes.func.isRequired,
    ViewEditComponent: PropTypes.elementType,
    onEditModeChange: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    onMove: PropTypes.func,
    showEntityFilters: PropTypes.bool,
    isInvoicePage: PropTypes.bool,
    onRowClick: PropTypes.func,
    onSortedDataChange: PropTypes.func,
    selectedInvoice: PropTypes.object,
    selectedItem: PropTypes.object,
    onFilterChange: PropTypes.func,
    allCategories: PropTypes.array,
    allEntities: PropTypes.array,
    onAddClick: PropTypes.func,
    showDateFilter: PropTypes.bool,
    onClose: PropTypes.func,
    isEditing: PropTypes.bool,
    onPrevious: PropTypes.func,
    onNext: PropTypes.func,
    onUpdateInvoice: PropTypes.func,
    organizationId: PropTypes.string,
    suppliers: PropTypes.array,
    customers: PropTypes.array,
    fetchAllData: PropTypes.func,
    pageType: PropTypes.oneOf(['invoice', 'category', 'entity', 'accountant-dashboard']).isRequired,
    invoiceType: PropTypes.oneOf(['expense', 'revenue']).isRequired,
    // fetchAllData: PropTypes.func.isRequired,
    createAnalyticsTrigger: PropTypes.func,
    pollAnalyticsTriggerStatus: PropTypes.func,
    onAnalyticsUpdateStart: PropTypes.func,
    onAnalyticsUpdateEnd: PropTypes.func,

};

export default TableComponent;